import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#00b6ea',
    [Prop.LINK_TXT_HOVER]: '#de3d95',
    [Prop.BTN_BG]: '#00b6ea',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#de3d95',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#00b6ea',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#b3b3b3',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#00b6ea',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#d9d9d9',
    [Prop.ICON]: '#00b6ea',
    [Prop.ICON_HOVER]: '#3f3f3f',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#00B6EA',
    [Prop.HEADER]: '#ffffff',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#3f3f3f',
    [Prop.LINK_TXT_HOVER]: '#e947b6',
    [Prop.BTN_BG]: '#ffffff',
    [Prop.BTN_TXT]: '#00b6ea',
    [Prop.BTN_BG_HOVER]: '#de3d95',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#00b6ea',
    [Prop.BTN_BG_INACTIVE]: '#3f3f3f',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#FFFFFF',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#3f3f3f',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#3f3f3f',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#3f3f3f',
    [Prop.HEADER]: '#ffffff',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#00b6ea',
    [Prop.LINK_TXT_HOVER]: '#de3d95',
    [Prop.BTN_BG]: '#00b6ea',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#FFFFFF',
    [Prop.BTN_TXT_HOVER]: '#de3d95',
    [Prop.BTN_BG_ACTIVE]: '#00b6ea',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#ebeff5',
    [Prop.BTN_TXT_INACTIVE]: '#3f3f3f',
    [Prop.ELEMENT]: '#00b6ea',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#00b6ea',
    [Prop.ICON]: '#00b6ea',
    [Prop.ICON_HOVER]: '#de3d95',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#EBEFF5',
    [Prop.HEADER]: '#3f3f3f',
    [Prop.CONTENT]: '#3f3f3f',
    [Prop.LINK_TXT]: '#00b6ea',
    [Prop.LINK_TXT_HOVER]: '#de3d95',
    [Prop.BTN_BG]: '#00b6ea',
    [Prop.BTN_TXT]: '#3f3f3f',
    [Prop.BTN_BG_HOVER]: '#de3d95',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#00b6ea',
    [Prop.BTN_TXT_ACTIVE]: '#3f3f3f',
    [Prop.BTN_BG_INACTIVE]: '#3f3f3f',
    [Prop.BTN_TXT_INACTIVE]: '#ebeff5',
    [Prop.ELEMENT]: '#00b6ea',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#3f3f3f',
    [Prop.ICON]: '#3f3f3f',
    [Prop.ICON_HOVER]: '#00b6ea',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#3f3f3f',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#de3d95',
    [Prop.LINK_TXT_HOVER]: '#00b6ea',
    [Prop.BTN_BG]: '#de3d95',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#00B6EA',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#de3d95',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#EBEFF5',
    [Prop.BTN_TXT_INACTIVE]: '#3f3f3f',
    [Prop.ELEMENT]: '#de3d95',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#de3d95',
    [Prop.ICON]: '#de3d95',
    [Prop.ICON_HOVER]: '#00b6ea',
  },
  [Components.DOR_LIFTS_TRAILS]: {
    [Prop.ICON_2]: '#00b6ea',
    [Prop.ICON_HOVER_2]: '#241F19',
    [Prop.HEADER_BG]: '#00b6ea',
    [Prop.HEADER_TXT]: '#241F19',
    [Prop.HEADER_ICON]: '#241F19',
    [Prop.HEADER_BG_2]: '#00b6ea',
    [Prop.HEADER_TXT_2]: '#241F19',
    [Prop.HEADER_ICON_2]: '#241F19',
    [Prop.TABLE_HEADER_BG]: '#b3b3b3',
    [Prop.TABLE_HEADER_TXT]: '#3F3F3F',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.NAV_BG]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#241F19',
    [Prop.NAV_ITEM_TXT_SOLID]: '#241F19',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#241F19',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#00b6ea',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#00b6ea',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#00b6ea',
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#00b6ea',
    [Prop.CLOSE_BTN_ICON]: '#241F19',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_TXT]: '#241F19',
    [Prop.MAIN_NAV_BORDER]: '#241F19',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#FFFFFF',
    [Prop.DRAWER_BTN_TXT]: '#00b6ea',
    [Prop.PROMO_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_BTN_TXT]: '#241F19',
    [Prop.SUB_NAV_BG]: '#EBEFF5',
    [Prop.SUB_NAV_TXT]: '#241F19',
    [Prop.SUB_NAV_BORDER]: '#241F19',
  },
  [Components.NAVIGATION_DRAWER]: {
    [Prop.NAV_PROMO_ITEM_TEXT]: '#FFFFFF',
  },
  [Components.SIDEBAR]: {
    [Prop.BACKGROUND]: '#DE3D95',
    [Prop.CONTENT]: '#FFFFFF',
  },

  [Components.NAVIGATION_TOGGLE]: {
    [Prop.NAV_TOGGLE_TXT]: '#FFFFFF',
    [Prop.NAV_TOGGLE_ICON]: '#FFFFFF',
    [Prop.NAV_TOGGLE_TXT_SCROLLED]: '#00b6ea',
    [Prop.NAV_TOGGLE_ICON_SCROLLED]: '#00b6ea',
  },
};
